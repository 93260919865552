<form class="form-inline justify-content-center">
  <div class="row range-datepicker">
    <div class="col">
      <div class="d-flex justify-content-center">
        <div class="form-group hidden">
          <div class="input-group">
            <input name="datepicker"
                   class="form-control border-0"
                   ngbDatepicker
                   #datepicker="ngbDatepicker"
                   [autoClose]="'outside'"
                   (dateSelect)="onDateSelection($event)"
                   [displayMonths]="2"
                   [dayTemplate]="t"
                   outsideDays="hidden"
                   [startDate]="fromDate!"
                   tabindex="-1">
            <ng-template #t let-date let-focused="focused">
        <span class="custom-day"
              [class.focused]="focused"
              [class.range]="isRange(date)"
              [class.faded]="isHovered(date) || isInside(date)"
              (mouseenter)="hoveredDate = date"
              (mouseleave)="hoveredDate = null">
          {{ date.day }}
        </span>
            </ng-template>
          </div>
        </div>
        <div class="form-group">
          <div class="input-group">
            <button class="btn btn-outline-secondary" (click)="datepicker.toggle()" type="button" style="height: fit-content; align-self: end;"><fa-icon [icon]="faCalendar"></fa-icon></button>
            <div class="flex-column">
              <label *ngIf="showLabels" for="start-date" class="input-label input-label-left mb-1">{{ 'rangeDatePicker.startDate' | translate }}</label>
              <input #dpFromDate
                     class="form-control mr-1 custom-date-input" placeholder="dd.mm.yyyy"
                     name="dpFromDate"
                     id="start-date"
                     [value]="formatter.format(fromDate)"
                     (input)="fromDate = validateInput(fromDate, dpFromDate.value, true); isDateRangeValid(); emitDates()"
                     [class.form-input-error]="errorFromDateFormatMsg">
            </div>
            </div>
        </div>
      </div>
    </div>
    <div class="col">
      <div class="form-group ml-2 justify-content-center">
        <div class="input-group d-flex justify-content-center">
          <div class="flex-column">
            <label *ngIf="showLabels" for="end-date" class="input-label input-label-left mb-1">{{ 'rangeDatePicker.endDate' | translate }}</label>
            <div class="d-flex flex-row align-items-center flex-nowrap">
              <input #dpToDate
                     class="form-control" placeholder="dd.mm.yyyy"
                     name="dpToDate"
                     id="end-date"
                     [value]="formatter.format(toDate)"
                     (input)="toDate = validateInput(toDate, dpToDate.value, false); isDateRangeValid(); emitDates()"
                     [class.form-input-error]="errorToDateFormatMsg || errorDateRangeMsg">
              <fa-icon [icon]="faCross" class="ml-2 clear-icon" (click)="clearDates()" *ngIf="enableClearSelection"></fa-icon>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
<div class="mt-2" *ngIf="errorToDateFormatMsg || errorFromDateFormatMsg || errorDateRangeMsg">
  <ngb-alert *ngIf="errorFromDateFormatMsg" type="danger" [dismissible]="false">{{ errorFromDateFormatMsg }}</ngb-alert>
  <ngb-alert *ngIf="errorToDateFormatMsg" type="danger" [dismissible]="false">{{ errorToDateFormatMsg }}</ngb-alert>
  <ngb-alert *ngIf="errorDateRangeMsg" type="danger" [dismissible]="false">{{ errorDateRangeMsg }}</ngb-alert>
</div>
