/**
 * This Service handles how the date is represented in scripts i.e. ngModel.
 */
import {NgbDateAdapter, NgbDateParserFormatter, NgbDatepickerI18n, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Injectable()
export class CustomAdapter extends NgbDateAdapter<string> {

  readonly DELIMITER = '-';

  fromModel(value: string | null): NgbDateStruct | null {
    if (value) {
      const date = value.split(this.DELIMITER);
      return {
        day : parseInt(date[0], 10),
        month : parseInt(date[1], 10),
        year : parseInt(date[2], 10)
      };
    }
    return null;
  }

  toModel(date: NgbDateStruct | null): string | null {
    return date ? date.day + this.DELIMITER + date.month + this.DELIMITER + date.year : null;
  }
}

/**
 * This Service handles how the date is rendered and parsed from keyboard i.e. in the bound input field.
 */
@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {

  readonly DELIMITER = '.';

  parse(value: string): NgbDateStruct | null {
    if (value) {
      const date = value.split(this.DELIMITER);
      return {
        day : parseInt(date[0], 10),
        month : parseInt(date[1], 10),
        year : parseInt(date[2], 10)
      };
    }
    return null;
  }

  format(date: NgbDateStruct | null): string {
    return date ? date.day + this.DELIMITER + date.month + this.DELIMITER + date.year : '';
  }
}

// Define custom service providing the months and weekdays translations
@Injectable()
export class CustomDatepickerI18n extends NgbDatepickerI18n {


  constructor(private translationService: TranslateService) { super(); }

  getWeekdayLabel(weekday: number): string {
    const weekdays = this.translationService.instant('calendar.weekdays').split(';');
    return weekdays[weekday - 1];
  }
  getWeekLabel(): string {
    return this.translationService.instant('calendar.weekLabel').split(';');
  }
  getMonthShortName(month: number): string {
    const months = this.translationService.instant('calendar.months').split(';');
    return months[month - 1];
  }
  getMonthFullName(month: number): string {
    const monthsFullName = this.translationService.instant('calendar.monthsFullName').split(';');
    return monthsFullName[month - 1];
  }
  getDayAriaLabel(date: NgbDateStruct): string { return `${date.day}-${date.month}-${date.year}`; }
}
