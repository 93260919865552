
export interface UserInterface {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  telNumber: string;
  isBanned: boolean;
  isAdmin: boolean;
  roleId: number;
  roles: ClerkInterface[];
}

export interface ClerkInterface {
  id: number;
  name: string;
  firstName?: string;
  lastName?: string;
}
