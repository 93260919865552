import { LatLngTuple } from 'leaflet';

export const common = {
  // baseUri: 'http://localhost:3000',
  // baseUri: 'http://192.168.178.21:3000',
  baseUri: 'https://esterhazy.maglo.at/api',
  userStorage: {
    refreshToken: 'storage_refreshToken',
    jwt: 'storage_jwt',
    userId: 'storage_userId'
  },
  defaultZoomLevel: 17,
  cityLatLng: [48.5625696, 16.0787228] as LatLngTuple
};
// https://gemeinde.maglo.at/api
// http://127.0.0.1:3000
// http://192.168.178.71:3000


























