<!-- div for styling the button on hover -->
<div (mouseover)="hover=true" (mouseleave)="hover=false" class="btn-box">
  <!-- so many ng styles and dynamic classes to style the button when it is hovered, active and in the normal state-->
  <!-- if there is a hover or the item is active, the background will be white and the color of the text in the specific color-->
  <button class="btn btn-secondary filter-btn" [class.filter-btn-active]="item.name===activeItem"
          [ngStyle]="{ 'color': (hover || item.name === activeItem) ? (hexCode || '#5C7B72FF') : 'white', 'background': hexCode, 'border-color': hexCode }" (click)="clickOverwrite(item);">
    <!-- the background of the icon stays always the same, because the are bind into like images and therefore the color can't be changed-->
    <app-icon [icon]="icon" class="icon" [ngStyle]="{ 'background': (hexCode || '#5C7B72FF') }"></app-icon>{{ item.name }}
  </button>
</div>
