import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Select, Store} from '@ngxs/store';
import {UserState} from '../../../modules/users/state/user.state';
import {Observable} from 'rxjs';
import {ClerkInterface, UserInterface} from '../../../interfaces/user.interface';
import {LoadClerks} from '../../../modules/users/state/user.actions';

@Component({
  selector: 'app-clerk-dropdown',
  templateUrl: './clerk-dropdown.component.html',
  styleUrls: ['./clerk-dropdown.component.scss']
})
export class ClerkDropdownComponent implements OnInit {

  @Select(UserState.clerks)
  clerks$: Observable<ClerkInterface[]>;

  @Output()
  selected: EventEmitter<any> = new EventEmitter<any>();

  @Input()
  selectedClerks: ClerkInterface[];

  constructor(private store: Store) { }

  ngOnInit(): void {
    this.store.dispatch(new LoadClerks({ search: null, limit: 100, page: 1 }));
  }

  clerksSelected(clerks: ClerkInterface[]) {
    const clerkIds = clerks.map((clerk) => {
      return clerk.id;
    });
    this.selected.emit(clerkIds);
  }

  compareWith(a, b) {
    return a?.id === b;
  }

}
